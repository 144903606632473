import React, { useEffect, useState } from "react";

import "../../Utility/style.css";

import { ArrowLeft } from "react-bytesize-icons";
import Header from "../../Components/Header";
import SurveyDetailsGridSystem from "../../Components/SurveyDetailsGridSystem";

import {
  FORMLIST_MODE,
  INTIMATED_ACTIVE,
  INTIMATED_NON_ACTIVE,
  METADATA_SYNC,
  SEARCHINPUT_PLACEHOLDER,
  SURVEY_FORMLIST_TABLEHEADER_DATA,
} from "../../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import api from "../../Config/api";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import PrintToDownload from "../../Assets/Image/PrintToDownload.png";
import DownloadFormsFiles from "../../Assets/Image/DownloadFormsFiles.png";
import getMetadata from "../../Utility/getMetadata";
import { NonIntimatedClaimsHeader } from "../../Constants/surveyFormListHeader";
import Error from "../Errors";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const ClaimApplicationDetails = (props) => {
  const _id = props.match.params.id;
  console.log("_id ===>", _id);
  const [loading, setLoading] = useState(true);
  const metadataReducer = useSelector((state) => state.metadata);
  const [id, setId] = useState("");

  const dispatch = useDispatch();

  const replaceNullWithDash = (data) => data || "﹘";

  const displayBoolean = (value) => {
    switch (value) {
      case null:
        return "﹘";
      case true:
        return "Yes";
      default:
        return "No";
    }
  };

  const SurveyNoDetailsHeaders = [
    "Survey No.",
    "Intimation No.",
    "Type of Survey",
    "Season",
    "Year",
    "Date of Premium Receipt",
    "Premium Amount Paid (In INR)",
    "Insured Through",
    "Name of the Intermediary",
  ];

  const [SurveyNoDetails, setSurveyNoDetails] = useState(
    SurveyNoDetailsHeaders.map((header) => ({ headerName: header, value: "" }))
  );

  //Function to update survey number details
  const dispatchSetSurveyNoDetails = (response) => {
    const responseArray = [
      response.survey_identifier,
      response.intimation_number,
      response.survey_type === "village_gplevel"
        ? "Village / GP Level"
        : response.survey_type?.charAt(0).toUpperCase() +
          response.survey_type?.slice(1),
      response.season,
      response.year,
      moment(response.premium_receipt_date?.toString().slice(0, 10))
        .format("DD/MM/YYYY")
        .toString(),
      response.premium_amount,
      response.insurance_intermediary_type,
      response.insurance_intermediary_name,
    ];

    setSurveyNoDetails(
      SurveyNoDetailsHeaders.map((header, idx) => ({
        headerName: header,
        value: replaceNullWithDash(responseArray[idx]),
      }))
    );
  };

  const DamageAreaHeaders = [
    "State",
    "District",
    "Taluka/Tehsil",
    "Block/Mandal/Revenue Circle",
    "Gram Panchayat",
    "Village/Mouza",
    "Crop Notified Level",
  ];

  const [DamageArea, setDamageArea] = useState(
    DamageAreaHeaders.map((header) => ({ headerName: header, value: "" }))
  );

  //Function to update damage area details
  const dispatchSetDamageArea = (response) => {
    const responseArray = [
      response?.state?.state_name,
      response?.district?.district_name,
      response?.tehsil?.tehsil_name,
      response?.block?.block_name,
      response?.panchayat?.panchayat_name,
      response?.village?.village_name,
      response?.crop_notified_level?.crop_notified_level,
    ];

    setDamageArea(
      DamageAreaHeaders.map((header, idx) => ({
        headerName: header,
        value: replaceNullWithDash(responseArray[idx]),
      }))
    );
  };

  const FarmerDetailsHeaders = [
    "Farmer Name",
    "Relative Name",
    "Farmer Mobile No.",
    "Farmer KCC No.",
    "Bank Name",
    "Bank AC No.",
    "Bank IFSC Code",
    "Bank Branch Name",
  ];

  const [FarmerDetails, setFarmerDetails] = useState(
    FarmerDetailsHeaders.map((header) => ({ headerName: header, value: "" }))
  );

  //Function to update farmer details
  const dispatchSetFarmerDetails = (response) => {
    const responseArray = [
      response?.farmer_name,
      response?.relative_name,
      response?.mobile_number,
      response?.kcc_number,
      response?.bank?.bank_name,
      response?.bank_detail?.bank_account_number,
      response?.bank_detail?.ifsc_code,
      response?.bank?.branch_name,
    ];

    setFarmerDetails(
      FarmerDetailsHeaders.map((header, idx) => ({
        headerName: header,
        value: replaceNullWithDash(responseArray[idx]),
      }))
    );
  };

  const CropSurveyDetailsHeaders = [
    "Date of Loss",
    "Date of Intimation",
    "Date of Survey",
    "Date of Sowing",
    "Date of Harvesting",
    "Khasra No.",
    "Insured(Yes/No)",
    "Insured Area [in ha]",
    "Affected Area (Intimated) [in ha]",
    "Affected Area (Surveyed) [in ha]",
    "Loss(%)",
    "Likely Recovery(%)",
    "Crop name as per intimation",
    "Crop available during survey",
    "Stage of Crop during Survey",
    "Intercopping Crop Name",
    "Intercropping Ratio",
    "Cause of Loss",
    "Comment on Loss",
    "Crop Condition during Survey",
    "Comment on Crop Condition",
  ];

  const [CropSurveyDetails, setCropSurveyDetails] = useState(
    CropSurveyDetailsHeaders.map((header) => ({
      headerName: header,
      value: "",
    }))
  );

  const displayDate = (date) =>
    date ? moment(date).format("DD/MM/YYYY") : null;

  //Function to update crop survey details
  const dispatchSetCropSurveyDetails = (response) => {
    const responseArray = [
      displayDate(response.date_of_loss),
      displayDate(response.date_of_intimation),
      displayDate(response.date_of_survey),
      displayDate(response.crop_detail?.date_of_sowing),
      displayDate(response.crop_detail?.date_of_harvesting),
      response.khashra_no ||
        response.survey_detail?.insured_area_detail?.khasra_number,
      displayBoolean(response.insured),
      response.survey_detail?.insured_area_detail?.insured_area,
      response?.area_affected_in_ha,
      response?.loss_detail?.affected_area_ao,
      response.loss_detail?.loss_percentage?.toString(),
      response.loss_detail?.likely_recovery_percentage?.toString(),
      response.survey_detail?.insured_area_detail?.crop?.crop_name,
      response.actual_crop_id_id &&
        metadataReducer.crop.filter(
          (c) => c.id === response.actual_crop_id_id
        )[0].crop_name,
      response.survey_detail?.insured_area_detail?.crop_stage?.crop_stage_name,
      response.crop_detail?.intercropping_crop_name,
      response.crop_detail?.intercropping_ratio,
      response.cause_of_loss?.charAt(0).toUpperCase() +
        response.cause_of_loss?.slice(1).replace(/_/g, " "),
      response.remark,
      response.survey_detail?.insured_area_detail?.crop_condition
        ?.crop_condition_name,
      response.remark_on_crop_condition,
    ];

    setCropSurveyDetails(
      CropSurveyDetailsHeaders.map((header, idx) => ({
        headerName: header,
        value: replaceNullWithDash(responseArray[idx]),
      }))
    );
  };

  const GovtOfficeWitnessHeaders = [
    "Name of Govt. Officer",
    "Govt. Officer Contact No.",
    "Deparment/Designation",
    "Witness Name",
    "Witness Contact Number",
  ];

  const [GovtOfficeWitness, setGovtOfficeWitness] = useState(
    GovtOfficeWitnessHeaders.map((header) => ({
      headerName: header,
      value: "",
    }))
  );

  //Function to update government office details
  const dispatchSetGovtOfficeWitness = (response) => {
    const responseArray = [
      response.gov_officer_name,
      response.gov_officer_mobile_number,
      response.gov_officer_dept?.charAt(0).toUpperCase() +
        response.gov_officer_dept?.slice(1),
      response.witness_name,
      response.witness_mobile_number,
    ];

    setGovtOfficeWitness(
      GovtOfficeWitnessHeaders.map((header, idx) => ({
        headerName: header,
        value: replaceNullWithDash(responseArray[idx]),
      }))
    );
  };

  const [DamageVideoImage, setDamageVideoImage] = useState([
    {
      headerName: "Farmer Bank Passbook",
      value: null,
    },
    {
      headerName: "Farmer Id Card",
      value: null,
    },
    {
      headerName: "Other Documents",
      value: [null, null, null, null, null, null, null, null, null, null],
    },
    {
      headerName: "Overview of Field",
      value: null,
    },
    {
      headerName: "Damage Field Photo with Farmer",
      value: null,
    },
    {
      headerName: "Two Closeup View of Damage Plants",
      value: [null, null],
    },
    {
      headerName: "30sec Video of Damage Crop",
      value: null,
    },
    {
      headerName: "Four Corners of Intimated Field",
      value: [null, null, null, null],
    },
    {
      headerName: "Signed Survey Form",
      value: null,
    },
  ]);

  const sortByDate = () => (a, b) =>
    new Date(b.updated_at) - new Date(a.updated_at);

  const dispatchSetDamageVideoImage = (response) => {
    console.log(
      "response inside dispatcher",
      response.survey_details
        ?.filter((d) => d.detail_type === "second_corner")
        .sort(sortByDate)[0]?.detail_value
    );

    setDamageVideoImage([
      {
        headerName: "Farmer Bank Passbook",
        value: response.survey_details
          ?.filter((d) => d.detail_type === "farmer_bank_passbook")
          .sort(sortByDate)[0]?.detail_value,
      },
      {
        headerName: "Farmer Id Card",
        value: response.survey_details
          ?.filter((d) => d.detail_type === "farmer_id_card")
          .sort(sortByDate)[0]?.detail_value,
      },
      {
        headerName: "Other Documents",
        value: response.survey_details
          ?.filter((d) => d.detail_type === "other")
          .sort(sortByDate).slice(0, 10).reverse()
          ?.map((o) => o.detail_value),
      },
      {
        headerName: "Overview of Field",
        value: response.survey_details
          ?.filter((d) => d.detail_type === "overview_of_field")
          .sort(sortByDate)[0]?.detail_value,
      },
      {
        headerName: "Damage Field Photo with Farmer",
        value: response.survey_details
          ?.filter((d) => d.detail_type === "damage_field_photo_with_farmer")
          .sort(sortByDate)[0]?.detail_value,
      },
      {
        headerName: "Two Closeup View of Damage Plants",
        value: response.survey_details
          ?.filter((d) => d.detail_type === "two_closeup_view_of_damage_plants")
          .sort(sortByDate)
          .slice(0, 2)
          ?.map((c) => c.detail_value),
      },
      {
        headerName: "30sec Video of Damage Crop",
        value: response.survey_details
          ?.filter((d) => d.detail_type === "half_minute_video_of_damage_crop")
          .sort(sortByDate)[0]?.detail_value,
      },
      {
        headerName: "Four Corners of Intimated Field",
        value: [
          response.survey_details
            ?.filter((d) => d.detail_type === "first_corner")
            .sort(sortByDate)[0]?.detail_value,
          response.survey_details
            ?.filter((d) => d.detail_type === "second_corner")
            .sort(sortByDate)[0]?.detail_value,
          response.survey_details
            ?.filter((d) => d.detail_type === "third_corner")
            .sort(sortByDate)[0]?.detail_value,
          response.survey_details
            ?.filter((d) => d.detail_type === "four_corner")
            .sort(sortByDate)[0]?.detail_value,
        ],
      },
      {
        headerName: "Signed Survey Form",
        value: response.survey_details
          ?.filter((d) => d.detail_type === "gov_off_sign")
          .sort(sortByDate)[0]?.detail_value,
      },
    ]);
  };

  const surveyFormListReducer = useSelector(
    (state) => state.surveyFormListReducer
  );

  /**
   * API Integration
   */

  const [surveyNumber, setSurveyNumber] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        dispatch({
          type: METADATA_SYNC,
          payload: await getMetadata(),
        });
      } catch (err) {
        console.log("API Error ===>", err);
        setError(500);
      }
    })();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    (async () => {
      if (metadataReducer.crop) {
        try {
          const response = await api.get(`/api/surveys/${_id}`);
          //console.log(accessToken)
          console.log("API Success ===>", response.data.survey);
          setId(response.data.survey.id);
          dispatchSetSurveyNoDetails(response.data.survey);
          dispatchSetDamageArea(
            response.data.survey.survey_detail?.insured_area_detail
          );
          dispatchSetFarmerDetails(response.data.survey?.farmer_detail?.farmer);
          dispatchSetCropSurveyDetails(response.data.survey);
          dispatchSetGovtOfficeWitness(response.data.survey);
          dispatchSetDamageVideoImage(response.data.survey);
          setSurveyNumber(response.data.survey.application_id);
          if (!response.data.survey.application_id) {
            // non-intimated claim
            dispatch({
              type: INTIMATED_ACTIVE,
              payload: "IntimatedClaim_nonActive",
            });
            dispatch({
              type: INTIMATED_NON_ACTIVE,
              payload: "IntimatedClaim_active",
            });
            dispatch({
              type: SEARCHINPUT_PLACEHOLDER,
              payload: "Search by Survey Number",
            });
            dispatch({
              type: SURVEY_FORMLIST_TABLEHEADER_DATA,
              payload: NonIntimatedClaimsHeader,
            });
            dispatch({
              type: FORMLIST_MODE,
              payload: "non-intimated",
            });
          }
        } catch (err) {
          console.log(err);
          setError(500);
        }

        setLoading(false);
      }
    })();
  }, [metadataReducer]);

  const handleNavigation = () => {
    props.history.replace({
      pathname: "/Survey-Form-List",
    });
  };

  const printForm = async () => {
    const win = window.open("/Printing");
    console.log(SurveyNoDetails[0].value);

    try {
      const response = await api.post("/api/lambda_functions/print_pdf", {
        survey_ids: [id],
      });

      const jobid = response.data.job_id;
      console.log("JobID", jobid);
      win.location = `/Printing?jobId=${jobid}`;
    } catch (err) {
      console.log("Error", err);
    }
  };

  const downloadZip = async () => {
    const win = window.open("/Zipping?jobId=Loading");
    try {
      const response = await api.post("/api/lambda_functions/download_zip", {
        survey_ids: [id],
      });
      console.log(response);

      win.location = `/Zipping?jobId=${response.data.job_id}`;
    } catch (err) {
      console.log("Error", err);
      win.location = `/Zipping?jobId=Failed`;
    }
  };

  if (error) {
    return <Error code={error} />;
  } else {
    return (
      <LoadingOverlay active={loading} spinner>
        <div className="surveyDetails_mainContainer">
          <div style={{position:'fixed',top:'0px',width:'100vw'}}>
            {/* Start Header Section */}
          <Header props={props} />

          {/* Start Pagination Section */}
          <div className="surveyDetails_firstContainer">
            <div className="col-lg-12 row">
              <div className="col-lg-7">
                <div className="col-12 row">
                  <div className="col-2">
                    {/* <Link
                  style={{ textDecoration: 'none' }}
                  to={{
                    pathname: `/Survey-Form-List`,
                  }}> */}
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => handleNavigation()}
                    >
                      <ArrowLeft width={25} height={25} color="#393939" />
                    </div>
                    {/* </Link> */}
                  </div>

                  <div className="col-7">
                    <h1 className="claimSurveyNo_text ">
                      {surveyFormListReducer.formListMode === "intimated" ? (
                        <>
                          Application No : <span>{surveyNumber}</span>
                        </>
                      ) : null}
                    </h1>
                  </div>

                  <div className="col-1">
                    <OverlayTrigger overlay={<Tooltip>Print</Tooltip>}>
                      <div style={{ cursor: "pointer" }} onClick={printForm}>
                        <img
                          src={PrintToDownload}
                          style={{ width: 30, height: 30 }}
                        />
                      </div>
                    </OverlayTrigger>
                  </div>

                  {/* {selectOfficerRoleReducer.selectOfficerType != 'Coordinating-Officer' ? ( */}
                  <div className="col-1">
                    <OverlayTrigger overlay={<Tooltip>Download</Tooltip>}>
                      <div style={{ cursor: "pointer" }} onClick={downloadZip}>
                        <img
                          src={DownloadFormsFiles}
                          style={{ width: 30, height: 30 }}
                        />
                      </div>
                    </OverlayTrigger>
                  </div>
                  {/* ) : null} */}
                </div>
              </div>

              {/* <div className="col-lg-5 col-sm-12 printStatus_container">
              <div className="col-12 row">
                <div className="col-lg-2 col-0"></div>

                <div className="col-lg-2 col-2">
                  <div style={{ cursor: 'pointer' }}>
                    <Print width={20} height={20} color="#7F8C8D" />
                  </div>
                </div>

                <div className="col-lg-8 col-sm-7 col-10 row ">
                  <div className="surveyDetails_statusContainer">
                    <div className="surveyDetails_statusText">Review Requested</div>

                    <div className="surveyDetails_buttomArrowContainer">
                      <ChevronBottom width={25} height={20} color="#000000" />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            </div>
          </div>
          </div>

          <div className="SurveyDetails_secondContainer">
            {/* Start Survey No. and Details Section */}
            <div className="SurveyDetails_HeaderContainer">
              <h4>Survey No. and Details</h4>
              <SurveyDetailsGridSystem
                surveyData={SurveyNoDetails}
                type="text"
              />
            </div>

            {/* Start Damage Area Details Section */}
            <div className="SurveyDetails_HeaderContainer">
              <h4>Damage Area Details</h4>
              <SurveyDetailsGridSystem surveyData={DamageArea} type="text" />
            </div>

            {/* Start Farmer Details Section */}
            <div className="SurveyDetails_HeaderContainer">
              <h4>Farmer Details</h4>
              <SurveyDetailsGridSystem surveyData={FarmerDetails} type="text" />
            </div>

            {/* Start Crop & Survey Related Details Section */}
            <div className="SurveyDetails_HeaderContainer">
              <h4>Crop & Survey Related Details</h4>
              <SurveyDetailsGridSystem
                surveyData={CropSurveyDetails}
                type="text/crop-survey-grid"
              />
            </div>

            {/* Start Govt office & Witness Details Section */}
            <div className="SurveyDetails_HeaderContainer">
              <h4>Govt Officer & Witness Details</h4>
              <SurveyDetailsGridSystem
                surveyData={GovtOfficeWitness}
                type="text"
              />
            </div>

            {/* Start Documents & Video of Crops Section */}
            <div className="SurveyDetails_HeaderContainer">
              <h4>Documents & Video of Crops</h4>
              {/* <SurveyDetailsGridSystem surveyData={FarmerInformationImage} type="image" /> */}
              <SurveyDetailsGridSystem
                surveyData={DamageVideoImage}
                type="image/video"
              />
              {/* <SurveyDetailsGridSystem surveyData={SignatureImage} type="image" /> */}
            </div>
          </div>
        </div>
      </LoadingOverlay>
    );
  }
};

export default ClaimApplicationDetails;

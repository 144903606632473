import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../../Utility/style.css";
import { Spinner } from "react-bootstrap";
import ZippedFile_Failed from "../../Assets/Image/ZippedFile_Failed.png";
import ZippedFile_Success from "../../Assets/Image/ZippedFile_Success.png";
import api from "../../Config/api";
import useInterval from "../../Hooks/useInterval";
import { zipLimit } from "../../Constants/limits";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Zipping = (props) => {
  const jobId = useQuery().get("jobId");
  const [loading, setLoading] = useState(true);

  const [errorCount, setErrorCount] = useState(0);

  const checkStatus = async () => {
    let statusMessage,
      _url = null;

    try {
      console.log(`Checking status for job_id:${jobId}`);
      if (jobId === "CannotProcessPayload") {
        setErrorCount(3);
      }

      // get status of a job
      const response = await api.get(
        `api/lambda_functions/print_pdf_status?job_id=${jobId}`
      );

      const APIResponsePayload = JSON.parse(response.data.payload[0]);
      console.log(APIResponsePayload);

      _url = APIResponsePayload?.payload?.url;
      statusMessage = APIResponsePayload?.status;

      if (statusMessage === "SUCCESS") {
        console.log(_url);
        setLoading(false);
        window.location = _url;
      }
      if (statusMessage === "FAILED") {
        setErrorCount(3);
      }
    } catch (err) {
      setErrorCount(errorCount + 1);
    }
  };

  useInterval(async () => {
    if (loading && errorCount < 3) {
      await checkStatus();
    }
  }, 2000);

  const textStyles = {
    fontSize: "30px",
    textAlign: "center",
    color: "#000",
    fontFamily: "Playfair Display",
    fontWeight: 700,
    marginBottom: "1rem",
  };

  let content;
  if (jobId === "NoPayload") {
    content = (
      <>
        <img
          src={ZippedFile_Failed}
          style={{ width: 70, height: 70, marginBottom: "10px" }}
        />
        <div style={textStyles}>Select surveys to download files!</div>
      </>
    );
  } else if (jobId === "CannotProcessPayload") {
    content = (
      <>
        <img
          src={ZippedFile_Failed}
          style={{ width: 70, height: 70, marginBottom: "10px" }}
        />
        <div style={textStyles}>
          Cannot zip more than {zipLimit} forms at a time!
        </div>
      </>
    );
  } else if (jobId === "Failed" || errorCount >= 3) {
    content = (
      <>
        <img
          src={ZippedFile_Failed}
          style={{ width: 70, height: 70, marginBottom: "10px" }}
        />
        <div style={textStyles}>Zipping Failed!</div>
      </>
    );
  } else if (loading) {
    content = (
      <>
        <img
          src={ZippedFile_Success}
          style={{ width: 70, height: 70, marginBottom: "10px" }}
        />
        <div style={textStyles}>Zipping in progress...</div>
        <Spinner animation="border" variant="primary" />
      </>
    );
  } else {
    content = (
      <>
        <img
          src={ZippedFile_Success}
          style={{ width: 70, height: 70, marginBottom: "10px" }}
        />
        <div style={textStyles}>Zipping was Sucessful!</div>
      </>
    );
  }

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {content}
    </div>
  );
};

export default Zipping;

// Login Page Section
export const AUTH_ID = "AUTH_ID";
export const LOGIN_DATA = "LOGIN_DATA";
export const PHONE_NUMBER = "PHONE_NUMBER";

// Selected Role Page Section
export const SELECT_OFFICER_TYPE = "SELECT_OFFICER_TYPE";

// Header Page Section
export const SEARCHINPUT_PLACEHOLDER = "SEARCHINPUT_PLACEHOLDER";
export const SEARCH_VALUE = "SEARCH_VALUE";

// Survey Form List Page Section
export const SURVEY_FORMLISTDATA = "SURVEY_FORMLISTDATA";
export const SURVEY_FILTER_FORMLISTDATA = "SURVEY_FILTTER_FORMLISTDATA";
export const SURVEY_FORMLIST_TABLEHEADER_DATA =
  "SURVEY_FORMLIST_TABLEHEADER_DATA";
export const SURVEY_FORMLIST_HEADER_CHECKBOX =
  "SURVEY_FORMLIST_HEADER_CHECKBOX";
export const SURVEY_FORM_PRINTLIST = "SURVEY_FORM_PRINTLIST";
export const FORMLIST_MODE = "FORMLIST_MODE";
export const INTIMATED_ACTIVE = "INTIMATED_ACTIVE";
export const INTIMATED_NON_ACTIVE = "INTIMATED_NON_ACTIVE";

export const SURVEY_FILTERS = "SURVEY_FILTERS"

export const SURVEY_FILTERS_PAGE = "SURVEY_FILTERS_PAGE" 
export const SURVEY_FILTERS_PER_PAGE = "SURVEY_FILTERS_PER_PAGE"
export const SURVEY_FILTERS_TYPE = "SURVEY_FILTERS_TYPE"
export const SURVEY_FILTERS_DATE_FROM = "SURVEY_FILTERS_DATE_FROM"
export const SURVEY_FILTERS_DATE_TO = "SURVEY_FILTERS_DATE_TO"
export const SURVEY_FILTERS_STATUS = "SURVEY_FILTERS_STATUS"
export const SURVEY_FILTERS_AO = "SURVEY_FILTERS_AO"
export const SURVEY_FILTERS_DISTRICT = "SURVEY_FILTERS_DISTRICT"
export const SURVEY_FILTERS_CROP = "SURVEY_FILTERS_CROP"

export const METADATA_SYNC = "METADATA_SYNC"

export const PAGINATION_PER_PAGE = "PAGINATION_PER_PAGE"
export const PAGINATION_CURRENT_PAGE = "PAGINATION_CURRENT_PAGE"
export const PAGINATION_TOTAL_PAGES = "PAGINATION_TOTAL_PAGES"
export const PAGINATION_TOTAL_COUNT = "PAGINATION_TOTAL_COUNT"
export const PAGINATION_ALL = "PAGINATION_ALL"
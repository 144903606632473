import React, { useEffect, useRef, useState } from "react";
import "../../Utility/style.css";
import { SURVEY_FILTERS } from "../../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

const SurveyFormFilter = (props) => {
  const [filters, setFilters] = useState({
    status: [],
    date_from: undefined,
    date_to: undefined,
    district: [],
    crop: [],
    ao: [],
  });

  const dispatch = useDispatch();
  const surveyFormListReducer = useSelector(
    (state) => state.surveyFormListReducer
  );

  const [CropTypeCheckBox, setCropTypeCheckBox] = useState(false);
  const [allCropTypeName, setallCropTypeName] = useState([
    {
      name: "Rabi",
      checked: false,
    },
    {
      name: "Kharif",
      checked: false,
    },
  ]);

  const [AreaCheckBox, setAreaCheckBox] = useState(false);
  const [allAreaName, setallAreaName] = useState([
    {
      name: "Kolkata",
      checked: false,
    },
  ]);

  const [IsStatusCheckBox, setIsStatusCheckBox] = useState(false);
  const [AllStatus, setAllStatus] = useState([
    {
      id: "is_new",
      name: "New",
      checked: surveyFormListReducer.surveyFilters.status.find(
        (s) => s === "is_new"
      ),
    },
    {
      id: "active",
      name: "Active",
      checked: surveyFormListReducer.surveyFilters.status.find(
        (s) => s === "active"
      ),
    },
    {
      id: "submitted",
      name: "Submitted",
      checked: surveyFormListReducer.surveyFilters.status.find(
        (s) => s === "submitted"
      ),
    },
    {
      id: "complete",
      name: "Completed",
      checked: surveyFormListReducer.surveyFilters.status.find(
        (s) => s === "complete"
      ),
    },
  ]);

  const [showAO, setshowAO] = useState(false);
  const [AllAoName, setAllAoName] = useState([
    {
      name: "Sachin Gaikwand",
      checked: false,
    },
    {
      name: "Vishal Haridas",
      checked: false,
    },
  ]);

  const [dateshow, setDateshow] = useState(false);
  const [dateTo, setDateTo] = useState(
    surveyFormListReducer.surveyFilters.date_to
  );
  const [dateFrom, setDateFrom] = useState(
    surveyFormListReducer.surveyFilters.date_from
  );

  const metadataReducer = useSelector((state) => state.metadata);

  useEffect(() => {
    (async () => {
      try {
        const data = metadataReducer;
        const areas = data.districts.map((item) => ({
          id: item.id,
          name: item.district_name,
          checked: surveyFormListReducer.surveyFilters.district.find(
            (d) => d === item.id
          ),
        }));
        setallAreaName(areas);

        const cropsTypes = data.crop.map((item) => ({
          id: item.id,
          name: item.crop_name,
          checked: surveyFormListReducer.surveyFilters.crop.find(
            (c) => c === item.id
          ),
        }));
        setallCropTypeName(cropsTypes);

        const aoNames = data.ao.users.map((item) => ({
          id: item.id,
          name: item.full_name,
          phone: item.phone_number,
          checked: surveyFormListReducer.surveyFilters.ao.find(
            (a) => a === item.id
          ),
        }));
        console.log(aoNames);
        setAllAoName(aoNames);

        console.log(
          "Filters from redux ===>",
          surveyFormListReducer.surveyFilters
        );

        setFilters(surveyFormListReducer.surveyFilters);
      } catch (err) {
        console.log("Metadata API Error ===>", err);
      }
    })();
    console.log(
      "surveyFormListReducer  ",
      surveyFormListReducer.SurveyFormList
    );
  }, []);

  // click handlers
  const node = useRef();
  useEffect(() => {
    const handleClick = (e) => {
      if (!node.current.contains(e.target)) {
        props.cancelButton();
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  useEffect(() => {
    const checkedStatus = AllStatus.filter((status) => status.checked).map(
      (status) => status.id
    );
    const checkedAreas = allAreaName
      .filter((area) => area.checked)
      .map((area) => area.id);
    const checkedCrops = allCropTypeName
      .filter((crop) => crop.checked)
      .map((crop) => crop.id);
    const checkedAos = AllAoName.filter((ao) => ao.checked).map((ao) => ao.id);

    console.log("dates in state", dateTo, dateFrom);

    setFilters({
      status: checkedStatus,
      date_from: dateFrom,
      date_to: dateTo,
      district: checkedAreas,
      crop: checkedCrops,
      ao: checkedAos,
    });
  }, [AllStatus, allCropTypeName, AllAoName, allAreaName, dateTo, dateFrom]);

  useEffect(() => {
    console.log("filters", filters);
  }, [filters]);

  const handleSearchInput_value = () => {
    dispatch({
      type: SURVEY_FILTERS,
      payload: filters,
    });
    props.cancelButton();
  };

  return (
    <div className="surveyFormFilter_container" ref={node}>
      <span>Filters</span>
      <div
        className="rowsurveyFormFilter_mainItemCheckBox darow"
        onClick={() => setIsStatusCheckBox(!IsStatusCheckBox)}
      >
        <input
          type="checkbox"
          checked={IsStatusCheckBox||filters['status'].length>0}
          onChange={() => setIsStatusCheckBox(!IsStatusCheckBox)}
        />
        <span>Status </span>
      </div>
      {IsStatusCheckBox ? (
        <div className="rowsurveyFormFilter_innerCheckBoxContainer">
          {AllStatus.map((statusItem, statusIndex) => (
            <div className="aosubblock" key={statusIndex}>
              <input
                type="checkbox"
                checked={statusItem.checked}
                value={statusItem.name}
                onChange={() => {
                  const temp = AllStatus.map((item) =>
                    item.name !== statusItem.name
                      ? item
                      : {
                          id: statusItem.id,
                          name: statusItem.name,
                          checked: !statusItem.checked,
                        }
                  );
                  setAllStatus(temp);
                }}
              />
              <span>{statusItem.name}</span>
            </div>
          ))}
        </div>
      ) : null}

      <div
        className="rowsurveyFormFilter_mainItemCheckBox darow"
        onClick={() => setDateshow(!dateshow)}
      >
        <input
          type="checkbox"
          checked={dateshow || filters['date_to'] || filters['date_from']}
          onChange={() => setDateshow(!dateshow)}
        />
        <span>Date </span>
      </div>

      {dateshow ? (
        <div className="date_pick row">
          <div className="dpiker col-6">
            <span>To</span>
            <input
              type="date"
              style={{ fontSize: 11 }}
              value={dateTo?.split("/").join("-")}
              onChange={(event) =>
                setDateTo(moment(event.target.value).format("YYYY/MM/DD"))
              }
            />
          </div>
          <div className="dpiker col-6">
            <span>From</span>
            <input
              type="date"
              style={{ fontSize: 11 }}
              value={dateFrom?.split("/").join("-")}
              onChange={(event) =>
                setDateFrom(moment(event.target.value).format("YYYY/MM/DD"))
              }
            />
          </div>
        </div>
      ) : null}

      <div
        className="rowsurveyFormFilter_mainItemCheckBox darow"
        onClick={() => setAreaCheckBox(!AreaCheckBox)}
      >
        <input
          type="checkbox"
          checked={AreaCheckBox||filters['district'].length>0}
          onChange={() => setAreaCheckBox(!AreaCheckBox)}
        />
        <span>Area (Distrct) </span>
      </div>

      {AreaCheckBox ? (
        <div className="rowsurveyFormFilter_innerCheckBoxContainer">
          {allAreaName.map((areaItem, areaIndex) => (
            <div className="aosubblock" key={areaIndex}>
              <input
                type="checkbox"
                checked={areaItem.checked}
                onChange={() => {
                  const temp = allAreaName.map((item) =>
                    item.name !== areaItem.name
                      ? item
                      : {
                          id: areaItem.id,
                          name: areaItem.name,
                          checked: !areaItem.checked,
                        }
                  );
                  setallAreaName(temp);
                }}
              />
              <span>{areaItem.name}</span>
            </div>
          ))}
        </div>
      ) : null}
      {/* <div className="rowsurveyFormFilter_mainItemCheckBox">
        <input type="checkbox" />
        Crop
      </div> */}

      <div
        className="rowsurveyFormFilter_mainItemCheckBox darow"
        onClick={() => setCropTypeCheckBox(!CropTypeCheckBox)}
      >
        <input
          type="checkbox"
          checked={CropTypeCheckBox||filters['crop'].length>0}
          onChange={() => setCropTypeCheckBox(!CropTypeCheckBox)}
        />
        <span>Crop Name </span>
      </div>
      {CropTypeCheckBox ? (
        <div className="rowsurveyFormFilter_innerCheckBoxContainer">
          {allCropTypeName.map((cropType, cropIndex) => (
            <div className="aosubblock" key={cropIndex}>
              <input
                type="checkbox"
                checked={cropType.checked}
                onChange={() => {
                  const temp = allCropTypeName.map((item) =>
                    item.name !== cropType.name
                      ? item
                      : {
                          id: cropType.id,
                          name: cropType.name,
                          checked: !cropType.checked,
                        }
                  );
                  setallCropTypeName(temp);
                }}
              />
              <span>{cropType.name}</span>
            </div>
          ))}
        </div>
      ) : null}

      <div
        className="rowsurveyFormFilter_mainItemCheckBox darow"
        onClick={() => setshowAO(!showAO)}
      >
        <input
          type="checkbox"
          checked={showAO||filters['ao'].length>0}
          onChange={() => setshowAO(!showAO)}
        />
        <span>AO </span>
      </div>
      {showAO ? (
        <div className="rowsurveyFormFilter_innerCheckBoxContainer">
          {AllAoName.map((aoItem, aoIndex) =>
            aoItem.name ? (
              <div className="aosubblock" key={aoIndex}>
                <input
                  type="checkbox"
                  checked={aoItem.checked}
                  onChange={() => {
                    const temp = AllAoName.map((item) =>
                      item.id !== aoItem.id
                        ? item
                        : {
                            id: aoItem.id,
                            name: aoItem.name,
                            phone: aoItem.phone,
                            checked: !aoItem.checked,
                          }
                    );
                    setAllAoName(temp);
                  }}
                />
                <span>{aoItem.name}</span>
              </div>
            ) : null
          )}
        </div>
      ) : null}

      <div className="rowsurveyFormFilter_buttonContainer ">
        <div>
          <button
            className="rowsurveyFormFilter_buttonCancel"
            onClick={() => props.cancelButton()}
          >
            Cancel
          </button>
          <button
            className="rowsurveyFormFilter_buttonApply"
            onClick={() => {
              handleSearchInput_value();
            }}
          >
            Apply Filters
          </button>
        </div>
      </div>
    </div>
  );
};

export default SurveyFormFilter;

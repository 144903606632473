import React from "react";
import "../../Utility/style.css";
import { SELECT_OFFICER_TYPE } from "../../Redux/Action";
import { useDispatch } from "react-redux";
import { User } from "react-bytesize-icons";

const SelectYourRole = (props) => {
  const dispatch = useDispatch();

  const handleLogin = (page, type) => {
    dispatch({
      type: SELECT_OFFICER_TYPE,
      payload: type,
    });
    props.history.push({
      pathname: `/${page}`,
    });
  };

  return (
    <div className="SelectRole_mainContainer">
      <h3>WINGSURE</h3>
      <h1>Select Your Role</h1>
      {/* <h5>Lorem ipsum dolor sit amet, consectetur</h5>
      <h5>adipiscing elit</h5> */}

      <button
        onClick={() => handleLogin("Survey-Form-List", "Coordinating-Officer")}
      >
        <User width={70} height={70} color="#7F8C8D" />
        <br />
        Coordinating Officer
      </button>
      <button
        onClick={() => handleLogin("Survey-Form-List", "Insurance-Officer")}
      >
        <User width={70} height={70} color="#7F8C8D" />
        <br />
        Insurance Officer
      </button>
    </div>
  );
};

export default SelectYourRole;

/* eslint-disable jsx-a11y/alt-text */

import React, { useState } from "react";
import "../../Utility/style.css";
import { Modal } from "@material-ui/core";
import { Close } from "react-bytesize-icons";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import { useMediaQuery } from "react-responsive";

const SurveyDetailsGridSystem = (props) => {
  const [IsShowImageView, setIsShowImageView] = useState(false);
  const [ImageViewURL, setImageViewURL] = useState("");
  const [IsShowVideoView, setIsShowVideoView] = useState(false);
  const [VideoViewURL, setVideoViewURL] = useState("");

  const handleImageVire = (imageUrl) => {
    setIsShowImageView(true);
    setImageViewURL(imageUrl);
  };
  const handleVideoVire = (videoUrl) => {
    setIsShowVideoView(true);
    setVideoViewURL(videoUrl);
    console.log("Video url  ", videoUrl);
  };

  const widthBreakpoints = {
    20: "col-lg-2",
    40: "col-lg-4",
    60: "col-lg-6",
    80: "col-lg-8",
    100: "col-lg-10",
    120: "col-lg-12",
  };

  const getLgCol = (len) => {
    console.log("len", len);
    console.log(
      "col",
      Object.keys(widthBreakpoints)
        .filter((key) => key > len)
        .shift() ||
        Object.keys(widthBreakpoints)[Object.keys(widthBreakpoints).length - 1]
    );
    return widthBreakpoints[
      Object.keys(widthBreakpoints)
        .filter((key) => key > len)
        .shift() ||
        Object.keys(widthBreakpoints)[Object.keys(widthBreakpoints).length - 1]
    ];
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 700px)" });

  const textData = (data) => {
    if (!data) {
      return null;
    }

    return data.map((item, index) => (
      <div
        className={`${
          item.extraSpace ? getLgCol(item.value.length) : "col-lg-2"
        } col-md-3 col-6 SurveyDetailsGrid_list_columnContainer`}
        key={index}
      >
        <h5>{item.headerName}</h5>
        <h6>{item.value}</h6>
      </div>
    ));
  };

  const textCropSurveyGrid = (data) => {
    return (
      <>
        <div className="row">
          {[
            [0, 5],
            [5, 10],
            [10, 16],
          ].map(([start, end]) => (
            <div className="row">
              {data.slice(start, end).map((item, index) => (
                <div
                  className={`${
                    item.extraSpace ? getLgCol(item.value.length) : "col-lg-2"
                  } col-md-3 col-6 SurveyDetailsGrid_list_columnContainer`}
                  key={index}
                >
                  <h5>{item.headerName}</h5>
                  <h6>{item.value}</h6>
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="row">
          {data[16] && (
            <div
              className={`col-lg-2 col-md-3 col-6 SurveyDetailsGrid_list_columnContainer`}
            >
              <h5>{data[16].headerName}</h5>
              <h6>{data[16].value}</h6>
            </div>
          )}
          {data[17] && (
            <div
              className={`col-lg-10 col-md-3 col-6 SurveyDetailsGrid_list_columnContainer`}
            >
              <h5>{data[17].headerName}</h5>
              <h6>{data[17].value}</h6>
            </div>
          )}
        </div>
        <div className="row">
          {data[18] && (
            <div
              className={`col-lg-2 col-md-3 col-6 SurveyDetailsGrid_list_columnContainer`}
            >
              <h5>{data[18].headerName}</h5>
              <h6>{data[18].value}</h6>
            </div>
          )}
          {data[19] && (
            <div
              className={`col-lg-10 col-md-3 col-6 SurveyDetailsGrid_list_columnContainer`}
            >
              <h5>{data[19].headerName}</h5>
              <h6>{data[19].value}</h6>
            </div>
          )}
        </div>
      </>
    );
  };

  const textImageData = (data) => {
    return (
      <div className="row">
        <div className="col-lg-3 col-md-4 col-sm-6 col-12 SurveyDetailsGrid_list_columnContainer">
          <h5>{data[0].headerName}</h5>
          <h6>{data[0].value}</h6>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-12 SurveyDetailsGrid_list_columnContainer">
          <h5>{data[1].headerName}</h5>
          <h6>{data[1].value}</h6>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-12 SurveyDetailsGrid_list_columnContainer">
          <h5>{data[2].headerName}</h5>
          <h6>{data[2].value}</h6>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-12 SurveyDetailsGrid_list_columnContainer">
          <h5>{data[3].headerName}</h5>
          <h6>{data[3].value}</h6>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-12 SurveyDetailsGrid_list_columnContainer">
          <h5>{data[4].headerName}</h5>
          <h6>{data[4].value}</h6>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-12 SurveyDetailsGrid_list_columnContainer">
          <h5>{data[5].headerName}</h5>
          {data[5].value && (
            <img
              src={data[5].value}
              onClick={() => handleImageVire(data[5].value)}
            />
          )}
        </div>
      </div>
    );
  };

  const imageData = (data) => {
    if (!data) {
      return null;
    }
    return data.map((item, index) => (
      <div
        className="col-lg-3 col-md-4 col-sm-6 col-12 SurveyDetailsGrid_list_columnContainer"
        key={index}
      >
        <h5>{item.headerName}</h5>
        <img src={item.value} onClick={() => handleImageVire(item.value)} />
      </div>
    ));
  };

  const imageVideoData = (data) => {
    return (
      <div className="row">
        <div className="col-lg-3 col-md-4 col-sm-6 col-12 SurveyDetailsGrid_list_columnContainer">
          <h5>{data[0].headerName}</h5>
          {data[0].value && (
            <img
              src={data[0].value}
              onClick={() => handleImageVire(data[0].value)}
            />
          )}
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-12 SurveyDetailsGrid_list_columnContainer">
          <h5>{data[1].headerName}</h5>
          {data[1].value && (
            <img
              src={data[1].value}
              onClick={() => handleImageVire(data[1].value)}
            />
          )}
        </div>
        <div className="col-12 SurveyDetailsGrid_list_columnContainer">
          <h5>{data[2].headerName}</h5>
          <div
            style={{ width: "80%", flexWrap: "wrap" }}
            className="SurveyDetailsGrid_list_multipleImageContainer other_docs_row"
          >
            {data[2].value.map(
              (item, index) =>
                item && (
                  <img
                    key={index}
                    src={item}
                    onClick={() => handleImageVire(item)}
                  />
                )
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-12 SurveyDetailsGrid_list_columnContainer">
          <h5>{data[3].headerName}</h5>
          {data[3].value && (
            <img
              src={data[3].value}
              onClick={() => handleImageVire(data[3].value)}
            />
          )}
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-12 SurveyDetailsGrid_list_columnContainer">
          <h5>{data[4].headerName}</h5>
          {data[4].value && (
            <img
              src={data[4].value}
              onClick={() => handleImageVire(data[4].value)}
            />
          )}
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-12 SurveyDetailsGrid_list_columnContainer">
          <h5>{data[5].headerName}</h5>
          <div
            style={{ width: "100%" }}
            className=" SurveyDetailsGrid_list_multipleImageContainer"
          >
            {data[5].value.map((item, index) =>
              item ? (
                <img
                  key={index}
                  src={item}
                  onClick={() => handleImageVire(item)}
                />
              ) : null
            )}
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-6 col-12 SurveyDetailsGrid_list_columnContainer">
          <h5>{data[6].headerName}</h5>
          {data[6].value && (
            <video
              className="video-thumbnail"
              width="50%"
              onClick={() => handleVideoVire(data[6].value)}
              style={{ cursor: "pointer", width: "auto" }}
            >
              <source src={data[6].value} type="video/mp4" />
            </video>
          )}
        </div>
        <div className="col-12 SurveyDetailsGrid_list_columnContainer">
          <h5>{data[7].headerName}</h5>
          <div
            style={{ width: "100%" }}
            className=" SurveyDetailsGrid_list_multipleImageContainer"
          >
            {data[7].value.map((item, index) =>
              item ? (
                <img
                  src={item}
                  key={index}
                  style={{ width: isTabletOrMobile ? "15%" : "10%" }}
                  onClick={() => handleImageVire(item)}
                />
              ) : null
            )}
          </div>
        </div>
        <div className="col-12 SurveyDetailsGrid_list_columnContainer">
          <h5>{data[8].headerName}</h5>
          {data[8].value && (
            <img
              src={data[8].value}
              onClick={() => handleImageVire(data[8].value)}
              style={{ width: isTabletOrMobile ? "15%" : "7%" }}
            />
          )}
        </div>
      </div>
    );
  };

  const gridData = () => {
    switch (props.type) {
      case "text":
        return textData(props.surveyData);
      case "text/crop-survey-grid":
        return textCropSurveyGrid(props.surveyData);
      case "text/image":
        return textImageData(props.surveyData);
      case "image":
        return imageData(props.surveyData);
      case "image/video":
        return imageVideoData(props.surveyData);
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="SurveyDetailsGrid_listContainer">
        <div className=" col-lg-12  ">
          <div className="row">{gridData()}</div>
        </div>
      </div>

      {IsShowImageView ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal
            open={IsShowImageView}
            disablePortal
            disableEnforceFocus
            disableAutoFocus
          >
            <div style={{ height: "100%" }}>
              <div
                style={{
                  padding: 5,
                  marginLeft: 40,
                  position: "absolute",
                  right: 20,
                  top: 130,
                  zIndex: 10,
                }}
              >
                <div
                  onClick={() => setIsShowImageView(false)}
                  style={{ cursor: "pointer", color: "#fff" }}
                >
                  <Close width={15} height={18} color="#ffff" />
                  {/* Close */}
                </div>
              </div>

              <div style={{ width: "100%", height: "80%" }}>
                {/* <span className="fullScreenButton" onClick={() => handleFullScreen()}>Full Screen</span> */}
                {/* <img src={ImageViewURL} style={{ width: '100%', height: '100%' }} /> */}
                <ReactPanZoom
                  image={ImageViewURL}
                  style={{ width: "100%", height: "90%" }}
                />
              </div>
            </div>
          </Modal>
        </div>
      ) : null}

      <Modal
        dialogClassName="modal-90w"
        show={IsShowVideoView}
        open={IsShowVideoView}
        //  style={{ height: window.innerHeight, }}
      >
        <div style={{ height: 200, margin: 0 }}>
          <div
            style={{
              width: "100%",
              textAlign: "right",
              paddingTop: 5,
              paddingRight: 20,
            }}
          >
            <div
              onClick={() => setIsShowVideoView(false)}
              style={{ cursor: "pointer" }}
            >
              <Close width={15} height={15} color="#ffffff" />
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            <video width="50%" height="70%" controls>
              <source src={VideoViewURL} type="video/mp4" />
            </video>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SurveyDetailsGridSystem;

import React, { useEffect, useRef } from "react";
import "../../Utility/style.css";
import { useSelector } from "react-redux";
import api from "../../Config/api";
import { Modal, Button } from "react-bootstrap";

import { zipLimit } from "../../Constants/limits";
import { useState } from "react";

const SurveyFormDownload = (props) => {
  const surveyFormListReducer = useSelector(
    (state) => state.surveyFormListReducer
  );

  const [downloadError,showDownloadError]=useState(false)

  const handleDownload = async () => {
    const applications_ids = surveyFormListReducer.surveyFormPrintList;
    if (applications_ids.length === 0) {
      showDownloadError(true)
      // const win = window.open("/Zipping?jobId=Loading");
      // win.location = `/Zipping?jobId=NoPayload`;
    } else if (applications_ids.length > zipLimit) {
      const win = window.open("/Zipping?jobId=Loading");
      win.location = `/Zipping?jobId=CannotProcessPayload`;
    } else {
      const win = window.open("/Zipping?jobId=Loading");
      try {
        const response = await api.post("/api/lambda_functions/download_zip", {
          survey_ids: applications_ids,
        });
        // console.log(response);

        win.location = `/Zipping?jobId=${response.data.job_id}`;
      } catch (err) {
        console.log("Error", err);
        win.location = `/Zipping?jobId=Failed`;
      }
    }
  };

  // click handlers
  const node = useRef();
  useEffect(() => {
    const handleClick = (e) => {
      if (!node.current.contains(e.target)) {
        props.cancelButton();
      }
    };
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  return (
    <>
      <Modal className="modalmn" show={downloadError}>
          <div>
            <Modal.Header>
              <Modal.Title>
                {" "}
                Select surveys to download files!
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalbdy">
              <h4>
                <span>You are trying to download</span>{" "}
                {surveyFormListReducer.surveyFormPrintList.length} Survey Form
                {surveyFormListReducer.surveyFormPrintList.length > 1 && <>s</>}
              </h4>
            </Modal.Body>
            <Modal.Footer className="modalbdr">
              <Button
                className="modalbtnC"
                variant="secondary"
                onClick={()=>showDownloadError(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </div>
        </Modal>
      <div className="surveyFormFilter_download" ref={node}>
        <p style={{ cursor: "pointer" }} onClick={handleDownload}>
          Download Files
        </p>
      </div>
    </>
  );
};

export default SurveyFormDownload;

import React from "react";
import { Minus, Plus } from "react-bytesize-icons";

const Counter = ({ increase, decrease, count }) => {
  const btnStyle = {
    backgroundColor: "#bfbfbf",
    color: "#2a2a35",
    cursor: "pointer",
    userSelect: "none",
  };

  return (
    <span style={{ border: "1px solid #9D9D9D", borderRadius: "5px" }}>
      <span style={btnStyle} onClick={decrease}>
        <Minus height={10} width={30} />
      </span>
      <span>
        {"     "}
        {count}
        {"     "}
      </span>
      <span style={btnStyle} onClick={increase}>
        <Plus height={15} width={30} />
      </span>
    </span>
  );
};

export default Counter;

const Error = ({ code }) => {
  return (
    <div
      className="row"
      style={{
        height: "100vh",
        width: "100vw",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1 style={{ textAlign: "center" }}>404</h1>
    </div>
  );
};

export default Error;

import React, { useEffect, useState, useRef } from "react";
import "../../Utility/style.css";
import { ChevronLeft } from "react-bytesize-icons";
import farmerPic from "../../Assets/Image/farmer_2d.png";
import Wingsure_Logo from "../../Assets/Image/Wingsure_Logo.png";
import { LOGIN_DATA } from "../../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import Auth from "@aws-amplify/auth";

let otp1 = "";
let otp2 = "";
let otp3 = "";
let otp4 = "";

const OTPVerification = (props) => {
  const [OTP1, setOTP1] = useState("");
  const [OTP2, setOTP2] = useState("");
  const [OTP3, setOTP3] = useState("");
  const [OTP4, setOTP4] = useState("");

  const [IsOtpEnter, setIsOtpEnter] = useState(false);
  const [otpValidity, setOtpValidity] = useState(null);
  const pin1ref = useRef(null);
  const pin2ref = useRef(null);
  const pin3ref = useRef(null);
  const pin4ref = useRef(null);
  const [resentOtpMsg, setResentOtpMsg] = useState(false);

  const dispatch = useDispatch();
  const loginReducer = useSelector((state) => state.loginReducer);

  useEffect(() => {
    pin1ref.current.focus();
    console.log("LoginReducer ===> ", loginReducer.loginData);
  }, []);

  const handleOTPVarification = async () => {
    try {
      const pin = `${OTP1}${OTP2}${OTP3}${OTP4}`;
      console.log(pin);

      const res = await Auth.sendCustomChallengeAnswer(
        loginReducer.loginData,
        pin
      );

      const signInUserSession = res.signInUserSession;
      if (signInUserSession != null) {
        setOtpValidity(true);
        props.history.replace({
          pathname: "/Survey-Form-List",
        });
      } else {
        setOtpValidity(false);
      }
    } catch (err) {
      setOtpValidity(false);
      console.log(err);
    }
  };

  const backToLogin = () => {
    props.history.replace({
      pathname: "/",
    });
  };

  const isOTPEnter = () => otp1 != "" && otp2 != "" && otp3 != "" && otp4 != "";

  const handleInputBoxes = (value, type) => {
    if (isNaN(value)) {
      return; // not a number, do not handle anything else
    }

    if (type == "otp1") {
      setOTP1(value);
      otp1 = value;
      otp1 === "" ? pin1ref.current.focus() : pin2ref.current.focus();
    }
    if (type == "otp2") {
      setOTP2(value);
      otp2 = value;
      otp2 === "" ? pin1ref.current.focus() : pin3ref.current.focus();
    }
    if (type == "otp3") {
      setOTP3(value);
      otp3 = value;
      otp3 === "" ? pin2ref.current.focus() : pin4ref.current.focus();
    }
    if (type == "otp4") {
      setOTP4(value);
      otp4 = value;
      if (otp4 === "") {
        pin3ref.current.focus();
      }
    }

    setIsOtpEnter(isOTPEnter);
  };

  const handleRecallOTP = async () => {
    const sess = await Auth.signIn(`+91${loginReducer.phoneNumber}`);
    dispatch({
      type: LOGIN_DATA,
      payload: sess,
    });
    setResentOtpMsg(true);
  };

  return (
    <div className="OTPVerification_mainContainer">
      <div
        className="OTPVerification_backButton"
        onClick={() => {
          backToLogin();
        }}
      >
        <ChevronLeft width={18} height={17} color="#7F8C8D" /> Back
      </div>
      <img src={Wingsure_Logo} className="OTPVerificatio_Wingsure_Logo" />
      <h1>OTP Sent</h1>
      <h5>We have send the OTP to your</h5>
      <h5>mobile number</h5>
      <img src={farmerPic} className="Login_bodyPic" />
      <h6>Enter the OTP</h6>

      <input
        ref={pin1ref}
        autoComplete="new-password"
        className={OTP1 ? "otp_input_active" : "otp_input"}
        value={OTP1}
        onChange={(e) => {
          handleInputBoxes(e.target.value, "otp1");
        }}
      />
      <input
        ref={pin2ref}
        autoComplete="new-password"
        className={OTP2 ? "otp_input_active" : "otp_input"}
        value={OTP2}
        onChange={(e) => {
          handleInputBoxes(e.target.value, "otp2");
        }}
      />
      <input
        ref={pin3ref}
        autoComplete="new-password"
        className={OTP3 ? "otp_input_active" : "otp_input"}
        value={OTP3}
        onChange={(e) => {
          handleInputBoxes(e.target.value, "otp3");
        }}
      />
      <input
        ref={pin4ref}
        autoComplete="new-password"
        className={OTP4 ? "otp_input_active" : "otp_input"}
        value={OTP4}
        onChange={(e) => {
          handleInputBoxes(e.target.value, "otp4");
        }}
      />

      <h4 className="reciveOTP">
        Didn't receive the OTP?{" "}
        <span onClick={() => handleRecallOTP()}>Resend OTP</span>
      </h4>

      {otpValidity === false ? (
        <div style={{ color: "red", marginTop: "0.5rem" }}>Invalid OTP</div>
      ) : null}

      {resentOtpMsg === true ? (
        <div style={{ color: "blue", marginTop: "0.5rem" }}>OTP resent!</div>
      ) : null}

      <button
        onClick={() => handleOTPVarification()}
        className={
          IsOtpEnter ? "OTPVerificationButton_active" : "OTPVerificationButton"
        }
        disabled={IsOtpEnter ? false : true}
      >
        Verify
      </button>
    </div>
  );
};

export default OTPVerification;

import React, { useEffect, useRef, useState } from "react";
import "../../Utility/style.css";
import { useSelector } from "react-redux";
import { Modal, Button, Spinner } from "react-bootstrap";
import File from "../../Assets/Image/File.png";
import Print_Successful from "../../Assets/Image/Print_Successful.png";
import { Close } from "react-bytesize-icons";
import api from "../../Config/api";

import Counter from "../Counter";
import { printLimit } from "../../Constants/limits";

const SurveyFormPrint = (props) => {
  const [showModal, setshowModal] = useState(false);
  const [PrintSelected, setPrintSelected] = useState(false);
  const [PrintAll, setPrintAll] = useState(false);
  const [PrintBlank, setPrintBlank] = useState(false);
  const [downloadSuccessPrint, setdownloadSuccessPrint] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [blankCount, setBlankCount] = useState(1);

  const surveyFormListReducer = useSelector(
    (state) => state.surveyFormListReducer
  );

  const handleClose = () => {
    setshowModal(false);
    props.cancelButton();
  };
  const handleShow = () => {
    setshowModal(true);
  };

  const handlePrintSelected = () => {
    handleShow();
    setPrintSelected(true);
    setPrintAll(false);
    setPrintBlank(false);
  };

  const handlePrintAll = () => {
    handleShow();
    setPrintSelected(false);
    setPrintAll(true);
    setPrintBlank(false);
  };

  const handlePrintBlank = () => {
    handleShow();
    setPrintSelected(false);
    setPrintAll(false);
    setPrintBlank(true);
  };

  const handlePrintingAPICall = async (applications_ids, win) => {
    if (applications_ids.length > printLimit) {
      win.location = `/Printing?jobId=CannotProcessPayload`;
    } else {
      try {
        const response = await api.post("/api/lambda_functions/print_pdf", {
          survey_ids: applications_ids,
        });

        const jobid = response.data.job_id;
        console.log("JobID", jobid);
        win.location = `/Printing?jobId=${jobid}`;
      } catch (err) {
        console.log("Error", err);
        win.location = `/Printing?jobId=CannotProcessPayload`;
      }
    }

    handleShow();
    setdownloadSuccessPrint(true);
    setPrintSelected(false);
    setPrintAll(false);
    setPrintBlank(false);
    setDownloadLoading(false);
  };

  const handleDownloadSelected = async () => {
    const win = window.open("/Printing");
    setDownloadLoading(true);
    const applications_ids = surveyFormListReducer.surveyFormPrintList;

    console.log("application_ids", applications_ids);

    await handlePrintingAPICall(applications_ids, win);
  };

  const handleDownloadAll = async () => {
    const win = window.open("/Printing");

    setDownloadLoading(true);
    const applications_ids = surveyFormListReducer.SurveyFormList.map(
      (s) => s._id
    );

    console.log("application_ids", applications_ids);

    await handlePrintingAPICall(applications_ids, win);
  };

  const handleDownloadBlank = async () => {
    const win = window.open("/Printing");
    setDownloadLoading(true);
    console.log("Trying to print", blankCount, "forms");

    if (blankCount > printLimit) {
      win.location = `/Printing?jobId=CannotProcessPayload`;
    } else {
      try {
        const response = await api.post(
          `/api/lambda_functions/print_empty_pdf`,
          null,
          {
            params: { number_of_survey: blankCount },
          }
        );

        const jobid = response.data.job_id;
        console.log("JobID", jobid);
        win.location = `/Printing?jobId=${jobid}`;
      } catch (err) {
        console.log("Error", err);
        win.location = `/Printing?jobId=CannotProcessPayload`;
      }
    }

    handleShow();
    setdownloadSuccessPrint(true);
    setPrintSelected(false);
    setPrintAll(false);
    setPrintBlank(false);
    setDownloadLoading(false);
  };

  useEffect(() => {
    console.log(blankCount);
  }, [blankCount]);

  // click handlers
  const node = useRef();
  useEffect(() => {
    const handleClick = (e) => {
      console.log(showModal);
      if (!showModal) {
        if (!node.current.contains(e.target)) {
          props.cancelButton();
        }
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [showModal]);

  const showDownloadLoading = downloadLoading ? (
    <Spinner animation="border" />
  ) : (
    "Download"
  );

  return (
    <div className="surveyFormFilter_print" ref={node}>
      <p onClick={handlePrintSelected}>Print Selected</p>
      <p onClick={handlePrintAll}>Print All</p>
      <p onClick={handlePrintBlank}>Print Blank</p>

      <Modal className="modalmn" show={showModal} onHide={handleClose}>
        {PrintSelected ? (
          <div>
            <Modal.Header>
              <Modal.Title>
                {" "}
                Download to Print Multiple Survey Form(s)?
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalbdy">
              <h4>
                <span>You are trying to print</span>{" "}
                {surveyFormListReducer.surveyFormPrintList.length} Survey Form
                {surveyFormListReducer.surveyFormPrintList.length > 1 && <>s</>}
              </h4>
            </Modal.Body>
            <Modal.Footer className="modalbdr">
              <Button
                className="modalbtnC"
                variant="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="modalbtnD"
                variant="primary"
                onClick={() => handleDownloadSelected()}
                disabled={!surveyFormListReducer.surveyFormPrintList.length > 0}
              >
                {showDownloadLoading}
              </Button>
            </Modal.Footer>
          </div>
        ) : null}

        {PrintAll ? (
          <div>
            <Modal.Header>
              <Modal.Title> Download to Print Survey Form(s)? </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalbdy">
              <h4>
                <span>You are trying to print </span>{" "}
                {surveyFormListReducer.SurveyFormList.length} Survey Form
                {surveyFormListReducer.SurveyFormList.length > 1 && <>s</>}
              </h4>
            </Modal.Body>
            <Modal.Footer className="modalbdr">
              <Button
                className="modalbtnC"
                variant="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="modalbtnD"
                variant="primary"
                onClick={() => handleDownloadAll()}
                disabled={!surveyFormListReducer.SurveyFormList.length > 0}
              >
                {showDownloadLoading}
              </Button>
            </Modal.Footer>
          </div>
        ) : null}

        {PrintBlank ? (
          <div>
            <Modal.Header>
              <Modal.Title>
                {" "}
                Download to Print Blank Survey Form(s)?{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalbdy">
              <h4>
                <span>You are trying to print </span>{" "}
                <Counter
                  count={blankCount}
                  increase={() => setBlankCount(blankCount + 1)}
                  decrease={() =>
                    blankCount > 1 ? setBlankCount(blankCount - 1) : undefined
                  }
                />{" "}
                Survey Form
                {blankCount > 1 && <>s</>}
              </h4>

              <div className="modalPicText_row">
                <h5>
                  <span>
                    {" "}
                    <img src={File} style={{ width: 40, height: 40 }} />
                  </span>{" "}
                  SurveyForm_Blank
                </h5>
              </div>
            </Modal.Body>
            <Modal.Footer className="modalbdr">
              <Button
                className="modalbtnC"
                variant="secondary"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="modalbtnD"
                variant="primary"
                onClick={() => handleDownloadBlank()}
              >
                {showDownloadLoading}
              </Button>
            </Modal.Footer>
          </div>
        ) : null}

        {downloadSuccessPrint ? (
          <div>
            <div
              className="closeIconContainer"
              onClick={() => {
                setdownloadSuccessPrint(false);
                handleClose();
                props.cancelButton();
              }}
            >
              <span>
                <Close width={10} height={8} color="#000000" />
              </span>
            </div>
            <Modal.Header>
              <Modal.Title style={{ fontSize: "30px" }}>
                {" "}
                Printing opened in a new tab{" "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalbdy">
              <img
                src={Print_Successful}
                style={{ width: 70, height: 70, marginBottom: "10px" }}
              />
            </Modal.Body>
          </div>
        ) : null}
      </Modal>
    </div>
  );
};

export default SurveyFormPrint;

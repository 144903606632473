/* eslint-disable import/no-anonymous-default-export */

import { SEARCHINPUT_PLACEHOLDER, SEARCH_VALUE } from "../Action";

const initialState = {
  searchPlaceholder: "Search by Application Number",
  searchValue: "",
};

export default function (state = initialState, action) {
  console.log("Reducer =====> ", state, action);
  switch (action.type) {
    case SEARCHINPUT_PLACEHOLDER:
      return {
        ...state,
        searchPlaceholder: action.payload,
      };
    case SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.payload,
      };

    default:
      return state;
  }
}

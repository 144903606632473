/* eslint-disable import/no-anonymous-default-export */

import { AUTH_ID, LOGIN_DATA, PHONE_NUMBER } from "../Action";

const initialState = {
  authId: {},
  loginData: {},
  phoneNumber: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case AUTH_ID:
      return {
        ...state,
        authId: action.payload,
      };

    case LOGIN_DATA:
      return {
        ...state,
        loginData: action.payload,
      };

    case PHONE_NUMBER:
      return {
        ...state,
        phoneNumber: action.payload,
      };

    default:
      return state;
  }
}

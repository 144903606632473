/* eslint-disable import/no-anonymous-default-export */

import { IntimatedClaimsHeader } from "../../Constants/surveyFormListHeader";
import {
  SURVEY_FILTER_FORMLISTDATA,
  SURVEY_FORMLISTDATA,
  SURVEY_FORMLIST_TABLEHEADER_DATA,
  SURVEY_FORMLIST_HEADER_CHECKBOX,
  SURVEY_FORM_PRINTLIST,
  FORMLIST_MODE,
  INTIMATED_ACTIVE,
  INTIMATED_NON_ACTIVE,
  SURVEY_FILTERS,
} from "../Action";

const initialState = {
  SurveyFilterFormList: [],
  SurveyFormList: [],
  surveyFormListHeader: IntimatedClaimsHeader,
  surveyFormPrintList: [],
  surveyFormHeaderCheckBox: false,
  formListMode: "intimated",
  intimated_claim: "IntimatedClaim_active",
  nonIntimated_claim: "IntimatedClaim_nonActive",
  surveyFilters: {
    date_from: undefined,
    date_to: undefined,
    status: [],
    ao: [],
    district: [],
    crop: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case INTIMATED_ACTIVE:
      return {
        ...state,
        intimated_claim: action.payload,
      };
    case INTIMATED_NON_ACTIVE:
      return {
        ...state,
        nonIntimated_claim: action.payload,
      };
    case SURVEY_FILTER_FORMLISTDATA:
      return {
        ...state,
        SurveyFilterFormList: action.payload,
      };
    case SURVEY_FORMLISTDATA:
      return {
        ...state,
        SurveyFormList: action.payload,
      };
    case SURVEY_FORMLIST_TABLEHEADER_DATA:
      return {
        ...state,
        surveyFormListHeader: action.payload,
      };
    case SURVEY_FORMLIST_HEADER_CHECKBOX:
      return {
        ...state,
        surveyFormHeaderCheckBox: action.payload,
      };
    case SURVEY_FORM_PRINTLIST:
      return {
        ...state,
        surveyFormPrintList: action.payload,
      };
    case FORMLIST_MODE:
      return {
        ...state,
        formListMode: action.payload,
      };
    case SURVEY_FILTERS:
      return {
        ...state,
        surveyFilters: action.payload,
      };
    default:
      return state;
  }
}

export const IntimatedClaimsHeader = [
  "Application Number",
  "Intimation Date",
  "Area",
  "Crop Name",
  "AO",
  "Status",
];

export const NonIntimatedClaimsHeader = [
  "Survey Number",
  "Survey Date",
  "Area",
  "Crop Name",
  "AO",
  "Status",
];

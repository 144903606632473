import React, { useState } from "react";
import "../../Utility/style.css";

import farmerPic from "../../Assets/Image/farmer_2d.png";
import Wingsure_Logo from "../../Assets/Image/Wingsure_Logo.png";
import tickPic from "../../Assets/Image/correctSign.png";
import Auth from "@aws-amplify/auth";
import { useDispatch } from "react-redux";
import { LOGIN_DATA, PHONE_NUMBER } from "../../Redux/Action";

const Login = (props) => {
  const [phoneNumber, setphoneNumber] = useState("");
  const [IsTenDigitPhoneNumber, setIsTenDigitPhoneNumber] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const dispatch = useDispatch();

  const handlePhoneNo = async (e) => {
    if (errorMsg !== "") {
      setErrorMsg("");
    }

    if (!isNaN(e.target.value)) {
      setphoneNumber(e.target.value);
      console.log(e.target.value.length);
      e.target.value.length > 9
        ? setIsTenDigitPhoneNumber(true)
        : setIsTenDigitPhoneNumber(false);
    }
  };

  const handleLogin = async () => {
    try {
      const sess = await Auth.signIn(`+91${phoneNumber}`);

      dispatch({
        type: LOGIN_DATA,
        payload: sess,
      });
      dispatch({
        type: PHONE_NUMBER,
        payload: phoneNumber,
      });

      props.history.replace({
        pathname: "/OTP-Verification",
      });
    } catch (e) {
      console.log(e);
      setErrorMsg("User does not exist");
    }
  };

  return (
    <div className="Login_mainContainer">
      <img src={Wingsure_Logo} className="Login_Wingsure_Logo" />
      <h1>Login</h1>
      <h5>OTP will be send to your mobile number</h5>
      <h5>on validation</h5>
      <img src={farmerPic} className="Login_bodyPic" />
      <h6>Enter your 10 digit Mobile Number</h6>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          className={
            IsTenDigitPhoneNumber
              ? "longin_imageContainer_active"
              : "longin_imageContainer"
          }
        >
          <input value="+91" className="CountryCodeInput" />
          <input
            placeholder="Enter mobile number"
            value={phoneNumber}
            onChange={handlePhoneNo}
            className={
              phoneNumber ? "mobileNumberInput_active" : "mobileNumberInput"
            }
          />
          {IsTenDigitPhoneNumber ? (
            <div className="tickImage_container">
              <img src={tickPic} />
            </div>
          ) : null}
        </div>
      </div>

      {errorMsg ? (
        <div style={{ color: "red", marginTop: "0.5rem" }}>{errorMsg}</div>
      ) : null}

      <br />

      <h6>
        By proceeding, you agree to the{" "}
        <a style={{ textDecoration: "none" }} href="https://wingsure.co/">
          terms and conditions
        </a>
      </h6>

      <button
        onClick={() => handleLogin()}
        disabled={IsTenDigitPhoneNumber ? false : true}
        className={IsTenDigitPhoneNumber ? "LoginButton_active" : "LoginButton"}
      >
        Login
      </button>
    </div>
  );
};

export default Login;

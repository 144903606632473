import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../../Utility/style.css";

import { Spinner } from "react-bootstrap";
import Print_Successful from "../../Assets/Image/Print_Successful.png";
import Print_Failed from "../../Assets/Image/Print_Failed.png";
import api from "../../Config/api";
import useInterval from "../../Hooks/useInterval";
import { printLimit } from "../../Constants/limits";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const Printing = (props) => {
  const jobId = useQuery().get("jobId");
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState(null);
  const [errorCount, setErrorCount] = useState(0);
  const [successFiles, setSuccessFiles] = useState([]);
  const [failureFiles, setFailureFiles] = useState([]);

  const checkStatus = async () => {
    let statusMessage,
      _url = null;

    try {
      console.log(`Checking status for job_id:${jobId}`);
      if (jobId === "CannotProcessPayload") {
        setErrorCount(3);
      }

      // get status of a job
      const response = await api.get(
        `api/lambda_functions/print_pdf_status?job_id=${jobId}`
      );

      const APIResponsePayload = JSON.parse(response.data.payload);
      console.log(APIResponsePayload);

      _url = APIResponsePayload?.payload?.url;
      statusMessage = APIResponsePayload?.status;

      if (statusMessage === "SUCCESS") {
        setUrl(_url);
        setLoading(false);
        setSuccessFiles(APIResponsePayload?.payload?.status?.success_ids);
        setFailureFiles(APIResponsePayload?.payload?.status?.failure_ids);
      }
      if (statusMessage === "FAILED") {
        setErrorCount(3);
      }
    } catch (err) {
      setErrorCount(errorCount + 1);
    }
  };

  useInterval(async () => {
    if (loading && errorCount < 3) {
      await checkStatus();
    }
  }, 2000);

  const textStyle = {
    fontSize: "30px",
    textAlign: "center",
    color: "#000",
    fontFamily: "Playfair Display",
    fontWeight: 700,
    marginBottom: "1rem",
  };

  if (loading) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {errorCount < 3 ? (
          <>
            <img
              src={Print_Successful}
              style={{ width: 70, height: 70, marginBottom: "10px" }}
            />
            <div style={textStyle}>Printing in progress...</div>
            <Spinner animation="border" variant="primary" />
          </>
        ) : (
          <>
            <img
              src={Print_Failed}
              style={{ width: 70, height: 70, marginBottom: "10px" }}
            />
            <div style={textStyle}>Failed to print the survey forms!</div>
            {jobId === "CannotProcessPayload" && (
              <div>Cannot print more than {printLimit} forms at a time!</div>
            )}
          </>
        )}
      </div>
    );
  } else {
    return (
      <div style={{ height: "100vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Print_Successful}
            style={{
              width: 70,
              height: 70,
              margin: " 10px 2rem",
            }}
          />
          <h3 style={textStyle}>
            Sucessfully printed {successFiles.length} survey forms!
          </h3>
        </div>
        {failureFiles.length > 0 ? (
          <div style={{ marginLeft: "1rem" }}>
            <h5>
              Failed to print the survey forms with the following survey nos.:
            </h5>
            <ul>
              {failureFiles.map((file) => (
                <li>{file}</li>
              ))}
            </ul>
          </div>
        ) : null}
        <div style={{ height: "100%" }}>
          <iframe width="100%" height="100%" src={url} />
        </div>
      </div>
    );
  }
};

export default Printing;

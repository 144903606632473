/* eslint-disable jsx-a11y/alt-text */

import React, { useEffect, useRef, useState } from "react";
import "../../Utility/style.css";
import { Search, Close, ChevronBottom } from "react-bytesize-icons";

import Wingsure_Logo from "../../Assets/Image/Wingsure_Logo.png";
import { SEARCH_VALUE } from "../../Redux/Action";
import { useSelector, useDispatch } from "react-redux";
import { Auth } from "aws-amplify";
import api from "../../Config/api";
import { Modal, Button } from "react-bootstrap";

const Header = (props) => {
  const [SearchValue, setSearchValue] = useState("");
  const [IsSignView, setIsSignView] = useState(false);
  const [username, setUsername] = useState(null);
  const [phone, setPhone] = useState(null);

  const dispatch = useDispatch();

  const [IsShowCloseIcon, setIsShowCloseIcon] = useState(false);
  const [showSignoutModal, setshowSignoutModal] = useState(false);

  const headerReducer = useSelector((state) => state.headerReducer);

  useEffect(() => {
    (async () => {
      const userInfo = await Auth.currentUserInfo();
      console.log("User Info ===>", userInfo);
      const response = await api.get("/api/users/me");
      setUsername(response.data.user.full_name);
      setPhone(userInfo.attributes.phone_number);
    })();
  }, []);

  const node = useRef();
  useEffect(() => {
    const handleClick = (e) => {
      if (node.current && !node.current.contains(e.target)) {
        setIsSignView(false);
      }
    };

    setSearchValue(headerReducer.searchValue);

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleSearchInput_value = (searchValue) => {
    setSearchValue(searchValue);

    dispatch({
      type: SEARCH_VALUE,
      payload: searchValue,
    });
  };

  const handleSignOut = async () => {
    await Auth.signOut();
    props.props.history.replace({
      pathname: `/`,
    });
  };

  const handleSearchCloseIcon = () => {
    let blankValue = "";
    setIsShowCloseIcon(false);
    handleSearchInput_value(blankValue);
  };

  const CloseIcon = () => (
    <div className=" searchIcon" onClick={() => handleSearchCloseIcon()}>
      <Close width={20} height={18} color="#000" />
    </div>
  );

  const SearchIcon = () => (
    <div className=" searchIcon">
      <Search width={20} height={18} color="#ffff" />
    </div>
  );

  const showIcon = IsShowCloseIcon ? <CloseIcon /> : <SearchIcon />;

  return (
    <>
      <div className="headerContainer">
        <div className="col-lg-12 row">
          <div className="col-lg-2 d-flex justify-content-center">
            <img src={Wingsure_Logo} className="headerLogo" />
          </div>

          <div className="col-lg-10 col-12 row">
            <div className="col-10">
              {props.searchbar ? (
                <div className="headerInput_container">
                  <input
                    className="headerInput"
                    placeholder={headerReducer.searchPlaceholder}
                    onChange={(e) => handleSearchInput_value(e.target.value)}
                    value={SearchValue}
                  />
                  {showIcon}
                </div>
              ) : null}
            </div>

            <div className="col-2 d-flex justify-content-center">
              <div className="Profile_container">
                <div className="profileName">{username}</div>
                <div
                  className=" arrowBottom"
                  onClick={() => setIsSignView(true)}
                  style={{ marginLeft: "1rem" }}
                >
                  <div style={{ cursor: "pointer" }}>
                    <ChevronBottom width={10} height={15} color="#393939" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {IsSignView ? (
          <div className="signinView_container" ref={node}>
            <div
              style={{
                width: "100%",
                textAlign: "right",
                paddingTop: 0,
                paddingRight: 10,
              }}
            >
              <div
                onClick={() => setIsSignView(false)}
                style={{ cursor: "pointer" }}
              >
                <Close width={8} height={8} color="#ffff" />
              </div>
            </div>

            <div className="signinView_innerContainer ">
              <h6>{phone}</h6>
              <button
                onClick={() => {
                  setshowSignoutModal(true);
                  setIsSignView(false);
                }}
              >
                Sign Out
              </button>
            </div>
          </div>
        ) : null}
      </div>

      <Modal
        className="modalmn"
        show={showSignoutModal}
        onHide={() => setshowSignoutModal(false)}
      >
        <div>
          <Modal.Header>
            <Modal.Title style={{ fontSize: "30px" }}>
              {" "}
              Do you want to sign out?{" "}
            </Modal.Title>
          </Modal.Header>

          <Modal.Footer className="modalbdr">
            <Button
              className="modalbtnC"
              variant="secondary"
              onClick={() => {
                setIsSignView(false);
                setshowSignoutModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="modalbtnD"
              variant="primary"
              onClick={() => handleSignOut()}
            >
              Sign Out
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
};

export default Header;


import { combineReducers } from 'redux';
import surveyFormListReducer from './surveyFormListReducer';
import selectOfficerRoleReducer from './selectOfficerRoleReducer';
import loginReducer from './loginReducer';
import headerReducer from './headerReducer';
import metadata from './metadata';
import pagination from './pagination';


const rootReducer = combineReducers({
    surveyFormListReducer,
    selectOfficerRoleReducer,
    loginReducer,
    headerReducer,
    metadata,
    pagination,
});

export default rootReducer;

import React, { useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";

import Auth from "@aws-amplify/auth";
import LoadingOverlay from "react-loading-overlay";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const [isLoggedIn, setisLoggedIn] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const session = await Auth.currentSession();
        console.log("Logged In Success  ===>", session);
        setisLoggedIn(true);
      } catch (err) {
        console.log("Logged In Error ===>", err);
        setisLoggedIn(false);
      }
    })();
  }, []);

  const getRoutedComponent = (props) => {
    let RoutedComponent;
    console.log(isLoggedIn);
    switch (isLoggedIn) {
      case null:
        RoutedComponent = (
          <LoadingOverlay active={true} spinner></LoadingOverlay>
        );
        break;
      case true:
        RoutedComponent = <Component {...props} />;
        break;
      default:
        RoutedComponent = <Redirect to="/" />;
    }

    return RoutedComponent;
  };

  return (
    <Route {...restOfProps} render={(props) => getRoutedComponent(props)} />
  );
}

export default ProtectedRoute;

import axios from "axios";
import { Auth } from "aws-amplify";

const url = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: url,
  headers: {
    Accept: "application/vnd.cs-api.v1",
  },
});

api.interceptors.request.use(async (config) => {
  const accessToken = (await Auth.currentSession())
    .getAccessToken()
    .getJwtToken();

  console.log("access token ===> ", accessToken);

  config.headers["Authorization"] = accessToken ? `Bearer ${accessToken}` : "";
  return config;
});

export default api;

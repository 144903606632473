import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Login from "../Screens/Login";
import OTPVerification from "../Screens/OTPVerification";
import Home from "../Screens/Home";
import ClaimApplicationDetails from "../Screens/ClaimApplicationDetails";
import SelectYourRole from "../Screens/SelectRole";
import UploadFileAdmin from "../Screens/UploadFileAdmin";

import ProtectedRoute from "../Components/Routes/ProtectedRoute";
import InitialRoute from "../Components/Routes/InitialRoute";
import Printing from "../Screens/Printing";
import Zipping from "../Screens/Zipping";

function Navigation(props) {
  return (
    <Router>
      <div>
        <Switch>
          <InitialRoute path="/" exact component={Login} />
          <InitialRoute path="/OTP-Verification" component={OTPVerification} />
          <ProtectedRoute path="/Select-Your-Role" component={SelectYourRole} />
          <ProtectedRoute path="/Survey-Form-List" component={Home} />
          <ProtectedRoute
            path="/Claim-Application-Details/:id"
            component={ClaimApplicationDetails}
          />
          <ProtectedRoute path="/Upload-File" component={UploadFileAdmin} />
          <ProtectedRoute path="/Printing" component={Printing} />
          <ProtectedRoute path="/Zipping" component={Zipping} />
        </Switch>
      </div>
    </Router>
  );
}

export default Navigation;

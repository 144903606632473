import { 
  PAGINATION_PER_PAGE, 
  PAGINATION_CURRENT_PAGE, 
  PAGINATION_TOTAL_PAGES, 
  PAGINATION_TOTAL_COUNT,
  PAGINATION_ALL
} from "../Action"

const initialState = {
  perPage: 10,
  currentPage: 1,
  totalPages: 0,
  totalCount: 0
}

export default function (state = initialState, action) {
  switch(action.type) {
    case PAGINATION_PER_PAGE: return {...state, perPage: action.payload}
    case PAGINATION_CURRENT_PAGE: return {...state, currentPage: action.payload}
    case PAGINATION_TOTAL_PAGES: return {...state, totalPages: action.payload}
    case PAGINATION_TOTAL_COUNT: return {...state, totalCount: action.payload}
    case PAGINATION_ALL: return {...state, ...action.payload}
    default: return state;
  }
}
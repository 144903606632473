import React, { useState, createRef } from "react";
import "../../Utility/style.css";
import { Download } from "react-bytesize-icons";
import Dropzone from "react-dropzone";
import Header from "../../Components/Header";
import api from "../../Config/api";
import LoadingOverlay from "react-loading-overlay";

let file = {};

const UploadFileAdmin = (props) => {
  const [ReadyForUpload, setReadyForUpload] = useState(false);
  const [SaveFile, setSaveFile] = useState({});
  const [ShowFileValue, setShowFileValue] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");
  const [IsCommentShow, setIsCommentShow] = useState(false);

  const [loading, setLoading] = useState(false);

  const dropzoneRef = createRef();

  const handleFile = (e) => {
    console.log("e  ====> ", e.target.files[0].name, e);
    if (
      e.target.files[0].type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      e.target.files[0].type == "application/vnd.ms-excel"
    ) {
      setShowFileValue(e.target.files[0].name);
      setSaveFile(e.target.files[0]);
      setReadyForUpload(true);
      setIsCommentShow(false);
      setErrorMessage("");
    } else {
      setSaveFile([]);
      setShowFileValue("");
      setReadyForUpload(false);
      setIsCommentShow(false);
      setErrorMessage("Please select xls, xlsx file");
    }
  };
  const handleDrop = (e) => {
    console.log("e  ====> ", e[0].name, e);
    if (
      e[0].type ==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      e[0].type == "application/vnd.ms-excel"
    ) {
      setShowFileValue(e[0].name);
      setSaveFile(e[0]);
      setReadyForUpload(true);
      setIsCommentShow(false);
      setErrorMessage("");
    } else {
      setSaveFile([]);
      setShowFileValue("");
      setReadyForUpload(false);
      setIsCommentShow(false);
      setErrorMessage("Please drap xls, xlsx file");
    }
  };
  const submitUploadFile = async () => {
    setLoading(true);
    try {
      let formData = new FormData();
      formData.append("survey_files", SaveFile);
      const response = await api.post(`api/upload_documents`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 201) {
        setIsCommentShow(true);
      } else {
        setErrorMessage("Please retry uploading the file");
        setIsCommentShow(false);
      }
    } catch (err) {
      console.log(err);
      setErrorMessage("Please retry uploading the file");
      setIsCommentShow(false);
    }
    setLoading(false);
    setReadyForUpload(false);
    setShowFileValue("");
  };
  return (
    <LoadingOverlay active={loading} spinner>
      <div
        className="UploadFileAdmin_mainContainer"
        style={{ height: window.innerHeight }}
      >
        {/* Start Header Section */}
        <Header props={props} />

        <div className="UploadFileAdmin_bodyContainer">
          <h2 className="uploadIntimated_headerText">Upload Intimation Data</h2>
          <div className="fileUpload_container">
            <h5>Upload the daily claim intimation detail file</h5>
            <Dropzone ref={dropzoneRef} onDrop={(e) => handleDrop(e)}>
              {({ getRootProps, getInputProps }) => (
                <div
                  className="col-12 row fileUploadArea_container"
                  onDrop={(e) => console.log("e   => ", e)}
                  {...getRootProps()}
                >
                  <div className="col-lg-10 col-sm-8 col-9 fileUploadArea_dragArea">
                    <Download
                      width={25}
                      height={25}
                      color="#2149e9"
                      className="DownloadIcon"
                    />

                    <input
                      placeholder="Drag and drop files here"
                      className="dragDropInput"
                      disabled={true}
                      value={ShowFileValue}
                    />
                  </div>
                  <div className="col-lg-2 col-sm-4 col-6 fileUploadArea_BrowserArea">
                    <input
                      type="file"
                      id="file"
                      onChange={(e) => handleFile(e)}
                      style={{ width: "100px", display: "none" }}
                      placeholder="Browse Files"
                      ref={(ref) => (file = ref)}
                    />
                    <div
                      className="fileUploadBrowseFileInput_container"
                      onClick={() => file.click()}
                    >
                      Browse Files
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
            <p className="fileUploadFile_supportFile">
              Supported files xls, xlsx
            </p>
            <h4
              className="fileUploadFile_fileComment"
              style={{ color: IsCommentShow ? "green" : "red" }}
            >
              {IsCommentShow
                ? "The file has been uploaded. You will recieve an email when it's processed"
                : ""}
              {ErrorMessage ? ErrorMessage : ""}
            </h4>
            <div className="fileUploadFileButton_container">
              <button
                className="fileUploadFileButton"
                onClick={() => submitUploadFile()}
                style={{
                  backgroundColor: ReadyForUpload ? "#2149e9" : "#878787",
                }}
                disabled={ReadyForUpload ? false : true}
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default UploadFileAdmin;

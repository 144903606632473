import "./App.css";
import { Provider } from "react-redux";
import Store from "./Redux/Store";
import Navigation from "./Navigation";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";

function App() {
  return (
    <div>
      <Provider store={Store}>
        <Navigation />
      </Provider>
    </div>
  );
}

export default App;
